import { actionCreators } from "../actions/process-actions";
import { IDevice } from '@piceasoft/core';
import { DeviceAttributes } from '@piceasoft/core';
import { consoleStyles } from "./console";
import { PiceaApplicationState, PiceaApplicationStateEventArgs, PiceaConnectionEventArgs, PiceaConnectionTypes, PiceaDevice, PiceaDeviceEventArgs, PiceaError } from "./picea.typings";

/** Инициализирует обработчики функций обратного вызова от Picea® Services. */
export const initializePicea = () => {

    window.PICEA = {
        /** Срабатывает при установке соединения c Online JavaScript API. */
        onConnected: (handler: (args: PiceaConnectionEventArgs) => void) => {
            //console.debug("%c" + "addEventListener: ONLINE_API.onConnected", consoleStyles.addEventListener);
            const f = (event: Event) => {
                console.debug("%c" + "eventHandler: ONLINE_API.onConnected", consoleStyles.eventHandler);
                const _event = event as CustomEventInit<PiceaConnectionEventArgs>;
                if (_event && _event.detail) {
                    handler(_event.detail);
                } else {
                    console.error(event);
                }
            }
            window.addEventListener('ONLINE_API.onConnected', f);
            return () => {
                window.removeEventListener('ONLINE_API.onConnected', f);
                //console.debug("%c" + "removeEventListener: ONLINE_API.onConnected", consoleStyles.addEventListener);
            };
        },

        /** Срабатывает при разрыве соединения c Online JavaScript API. */
        onDisconnected: (handler: (args: PiceaConnectionEventArgs) => void) => {
            //console.debug("%c" + "addEventListener: ONLINE_API.onDisconnected", consoleStyles.addEventListener);
            const f = (event: Event) => {
                console.debug("%c" + "eventHandler: ONLINE_API.onDisconnected", consoleStyles.eventHandler);
                const _event = event as CustomEventInit<PiceaConnectionEventArgs>;
                if (_event && _event.detail) {
                    handler(_event.detail);
                } else {
                    console.error(event);
                }
            }
            window.addEventListener('ONLINE_API.onDisconnected', f);
            return () => {
                window.removeEventListener('ONLINE_API.onDisconnected', f);
                //console.debug("%c" + "removeEventListener: ONLINE_API.onDisconnected", consoleStyles.addEventListener);
            };
        },

        /** Срабатывает при подключении устройства к Online JavaScript API. */
        onDeviceAdded: (handler: (args: PiceaDeviceEventArgs) => void) => {
            //console.debug("%c" + "addEventListener: ONLINE_API.onDeviceAdded", consoleStyles.addEventListener);
            const f = (event: Event) => {
                console.debug("%c" + "eventHandler: ONLINE_API.onDeviceAdded", consoleStyles.eventHandler);
                const _event = event as CustomEventInit<PiceaDeviceEventArgs>;
                if (_event && _event.detail) {
                    handler(_event.detail);
                } else {
                    console.error(event);
                }
            }
            window.addEventListener('ONLINE_API.onDeviceAdded', f);
            return () => {
                window.removeEventListener('ONLINE_API.onDeviceAdded', f);
                //console.debug("%c" + "removeEventListener: ONLINE_API.onDeviceAdded", consoleStyles.addEventListener);
            };
        },

        /** Срабатывает при получении дополнительных сведений об устройсте. */
        onDeviceUpdated: (handler: (args: PiceaDeviceEventArgs) => void) => {
            //console.debug("%c" + "addEventListener: ONLINE_API.onDeviceUpdated", consoleStyles.addEventListener);
            const f = (event: Event) => {
                console.debug("%c" + "eventHandler: ONLINE_API.onDeviceUpdated", consoleStyles.eventHandler);
                const _event = event as CustomEventInit<PiceaDeviceEventArgs>;
                if (_event && _event.detail) {
                    handler(_event.detail);
                } else {
                    console.error(event);
                }
            }
            window.addEventListener('ONLINE_API.onDeviceUpdated', f);
            return () => {
                window.removeEventListener('ONLINE_API.onDeviceUpdated', f);
                //console.debug("%c" + "removeEventListener: ONLINE_API.onDeviceUpdated", consoleStyles.addEventListener);
            };
        },

        /** Срабатывает при отключении устройства. */
        onDeviceRemoved: (handler: (args: PiceaDeviceEventArgs) => void) => {
            //console.debug("%c" + "addEventListener: ONLINE_API.onDeviceRemoved", consoleStyles.addEventListener);
            const f = (event: Event) => {
                console.debug("%c" + "eventHandler: ONLINE_API.onDeviceRemoved", consoleStyles.eventHandler);
                const _event = event as CustomEventInit<PiceaDeviceEventArgs>;
                if (_event && _event.detail) {
                    handler(_event.detail);
                } else {
                    console.error(event);
                }
            }
            window.addEventListener('ONLINE_API.onDeviceRemoved', f);
            return () => {
                window.removeEventListener('ONLINE_API.onDeviceRemoved', f);
                //console.debug("%c" + "removeEventListener: ONLINE_API.onDeviceRemoved", consoleStyles.addEventListener);
            };
        },

        onApplicationStateChanged: (handler: (args: PiceaApplicationStateEventArgs) => void) => {
            //console.debug("%c" + "addEventListener: ONLINE_API.onApplicationStateChanged", consoleStyles.addEventListener);
            const f = (event: Event) => {
                console.debug("%c" + "eventHandler: ONLINE_API.onApplicationStateChanged", consoleStyles.eventHandler);
                const _event = event as CustomEventInit<PiceaApplicationStateEventArgs>;
                if (_event && _event.detail) {
                    handler(_event.detail);
                } else {
                    console.error(event);
                }
            }
            window.addEventListener('ONLINE_API.onApplicationStateChanged', f);
            return () => {
                window.removeEventListener('ONLINE_API.onApplicationStateChanged', f);
                //console.debug("%c" + "removeEventListener: ONLINE_API.onApplicationStateChanged", consoleStyles.addEventListener);
            };
        }
    }
}

export const initializePiceaCallbacks = (dispatch: any) => {
    if (window.ONLINE_API) {

        /** Срабатывает при установке соединения c Online JavaScript API. */
        window.ONLINE_API.onConnected((error: PiceaError, type: PiceaConnectionTypes, data: any) => {
            console.debug("%c" + "Picea® Online Services: onConnected", consoleStyles.piceaCollback)
            console.info({ error, type, data });
            dispatch(actionCreators.addLog("PICEA", "[main] onConnected", { error, type, data }));
            window.dispatchEvent(new CustomEvent('ONLINE_API.onConnected', {
                detail: {
                    error: error,
                    connectionType: type,
                    data: data
                }
            } as CustomEventInit<PiceaConnectionEventArgs>));
        });

        /** Срабатывает при разрыве соединения c Online JavaScript API. */
        window.ONLINE_API.onDisconnected((error: any, type: PiceaConnectionTypes, data: any) => {
            console.debug("%c" + "Picea® Online Services: onDisconnected", consoleStyles.piceaCollback)
            console.info({ error, type });
            dispatch(actionCreators.addLog("PICEA", "[main] onDisconnected", { error, type }));
            window.dispatchEvent(new CustomEvent('ONLINE_API.onDisconnected', {
                detail: {
                    error: error,
                    connectionType: type
                }
            } as CustomEventInit<PiceaConnectionEventArgs>));
        });

        /** Срабатывает при подключении устройства к Online JavaScript API. */
        window.ONLINE_API.onDeviceAdded((error: PiceaError, type: PiceaConnectionTypes, data: PiceaDevice) => {
            console.debug("%c" + "Picea® Online Services: onDeviceAdded", consoleStyles.piceaCollback)
            console.info({ error, type, data });
            dispatch(actionCreators.addLog("PICEA", "[main] onDeviceAdded", { error, type, data }));
            window.dispatchEvent(new CustomEvent('ONLINE_API.onDeviceAdded', {
                detail: {
                    error: error,
                    connectionType: type,
                    device: data
                }
            } as CustomEventInit<PiceaDeviceEventArgs>));
        });

        /** Срабатывает при получении дополнительных сведений об устройсте. */
        window.ONLINE_API.onDeviceUpdated((error: any, type: any, data: any) => {
            console.debug("%c" + "Picea® Online Services: onDeviceUpdated", consoleStyles.piceaCollback)
            console.info({ error, type, data });
            dispatch(actionCreators.addLog("PICEA", "[main] onDeviceUpdated", { error, type, data }));
            window.dispatchEvent(new CustomEvent('ONLINE_API.onDeviceUpdated', {
                detail: {
                    error: error,
                    connectionType: type,
                    device: data
                }
            } as CustomEventInit<PiceaDeviceEventArgs>));
        });

        /** Срабатывает при отключении устройства. */
        window.ONLINE_API.onDeviceRemoved((error: any, type: any, data: any) => {
            console.debug("%c" + "Picea® Online Services: onDeviceRemoved", consoleStyles.piceaCollback)
            console.info({ error, type, data });
            dispatch(actionCreators.addLog("PICEA", "[main] onDeviceRemoved", { error, type, data }));
            window.dispatchEvent(new CustomEvent('ONLINE_API.onDeviceRemoved', {
                detail: {
                    error: error,
                    connectionType: type,
                    device: data
                }
            } as CustomEventInit<PiceaDeviceEventArgs>));
        });

        /** Срабатывает при изменении состояния приложения. */
        window.ONLINE_API.onApplicationStateChanged((error: PiceaError, type: PiceaConnectionTypes, data: PiceaApplicationState) => {
            console.debug("%c" + "Picea® Online Services: onApplicationStateChanged", consoleStyles.piceaCollback)
            console.info({ error, type, data });
            dispatch(actionCreators.addLog("PICEA", "[main] onApplicationStateChanged", { error, type, data }));
            window.dispatchEvent(new CustomEvent('ONLINE_API.onApplicationStateChanged', {
                detail: {
                    error: error,
                    type: type,
                    data: data
                }
            } as CustomEventInit<PiceaApplicationStateEventArgs>));
        });
    }
}

export const deviceBuild = (device: PiceaDevice): IDevice => {
    console.log('deviceBuild device', device)
    const _device: IDevice = {
        manufacturer: device.manufacturer,
        name: device.model_name,
        configuration: device.storage?.toString(),
        attributes: { },
        group: device.group,
        is_dual_sim: device?.model_info?.is_dual_sim,
        has_gsm: device?.model_info?.has_gsm ?? device.has_gsm,
        has_euicc: device?.model_info?.has_euicc ?? device.has_euicc
    }
    _device.attributes[DeviceAttributes.Capacity] = device.storage;
    _device.attributes[DeviceAttributes.Color] = device.color;
    if(device.imei){
        _device.attributes[DeviceAttributes.IMEI] = device.imei;
    }
    if(device.imei2){
        _device.attributes[DeviceAttributes.IMEI2] = device.imei2 || undefined;
    }
    _device.attributes[DeviceAttributes.SN] = device.serial;
    _device.attributes[DeviceAttributes.Image] = device.device_img ?? device.image_url;
    _device.attributes[DeviceAttributes.Model] = device.model_code;
    _device.attributes[DeviceAttributes.OS] = device.os;
    _device.attributes[DeviceAttributes.OS_Version] = device.os_version ?? device.sw_version;
    _device.attributes[DeviceAttributes.TID] = device.tid;
    _device.attributes[DeviceAttributes.FID] = device.fid;
    _device.attributes[DeviceAttributes.Hardware_id] = device.hardware_id;

    console.debug("%c" + "LOGIC deviceBuild(device)", consoleStyles.logic);
    console.info({ input: device, output: _device });

    return _device;
}
